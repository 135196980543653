@import 'normalize';

body {
  font-family: 'Montserrat';
  &.popup-open {
    overflow: hidden;
  }  
}
.titulo{
 text-align: center;
}
.container-condiciones{
  text-align: justify;
}
.list{
  text-align: justify;
}

#navbar{
  box-shadow: rgb(25 28 31 / 4%) 0px 8px 16px, rgb(25 28 31 / 4%) 0px -1px 0px;
}
.navbar-brand {
  padding: 0;
}

.social-links {
  flex: 1;
  justify-content: center;
  grid-gap: 10px;
  a{
    text-align: center;
    color: #fff;
    text-decoration: none;
    width: 45px;
    height: 45px;
    text-align: center;
    background-color: #000;
    display: inline-block;
    border-radius: 50%;
    line-height: 45px;
  }
}
.footer-bottom {
  flex: auto;
  text-align: center;
  .logo{
    margin-left: 30px;
  }
}

.container-section {
  h2 {
    font-weight: 600;
    font-size: 2rem;
    color: rgb(25, 28, 31);
    margin: 0px 0px 1.5rem;
    padding: 0px;
  }
  p {
    font-size: 1.5rem;
    color: rgb(139, 149, 158);
  }
}

.carousel-container {
  position: relative;
  overflow: hidden;
  .carousel-wrap {
    transform: translate3d(0, 0, 0);
    animation: moveSlideshow 50s linear infinite;
    display: flex;
    .carousel-card{
      height: 340px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
    }
  }
}
.card-custom {
  background: rgb(255, 255, 255);
  box-shadow: rgb(25 28 31 / 8%) 0px 14px 32px, rgb(25 28 31 / 4%) 0px 8px 16px, rgb(25 28 31 / 4%) 0px -1px 0px;
  border-radius: 1rem;
  width: 80vw;
  max-width: 400px;
  height: 256px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-left: 1.5rem;
  width: 400px;
  margin-left: 2.5rem;
}
@keyframes slideshow {
  0%    { left: 0; }
  100%  { left: -200%; }
}

@keyframes moveSlideshow {
  100% {
    -webkit-transform: translateX(-200%);
  }
}

// about
#about {
  .logo{
    margin-bottom: 25px;
    max-width: 90%;
  }
  .col-image {
    position: relative;
    .play-video {
      position: absolute;
      bottom: 154px;
      right: 75px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      background-color: rgb(255, 255, 255);
      border-radius: 50%;
      width: 5.25rem;
      height: 5.25rem;
      align-self: center;
      margin: auto;
      cursor: pointer;
      font-size: 40px;
      border: 0;
      box-shadow: 0 0 20px rgb(0 0 0 / 28%);
      transition: all .4s ease;
      &:focus {
        outline: none;
      }
      &:hover {
        transform: scale(1.3);
      }
    }
  }
}

#slogan{
  background: #fdfafa;
  padding: 40px 0;
}

#footer {
  padding: 30px 0;
  .navbar-nav {
    flex-direction: initial;
    margin-bottom: 10px;
    .nav-item {
      margin-right: 15px;
      a{
        color: rgb(139, 149, 158);
        text-decoration: none;
        transition: color 0.2s ease 0s;
        &:hover {
          color: #000;
        }
      }
    }
  }
}

.full-screen{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  color: #000;
  z-index: 9999;
}

.flex-container-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hidden{
  display: none;
}

video {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.295);
}

#btnClosePopup{
  position: absolute;
  top: 50px;
  right: 50px;
  border: 1px solid #000;
  border-radius: 10px;
  width: 60px;
  background: #fff;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: rgb(117, 125, 133);
  &:hover {
    color: rgb(25, 28, 31);
  }
  &:focus {
    outline: none;
  }
}

.list{
  list-style: none;
  margin: 0px;
  padding: 0px;
  font-size: 1.5rem;

  li {
    position: relative;
    color: rgb(139, 149, 158);
    margin: 1rem 0px 1rem 1.5rem;
    list-style: none;
    padding: 0.5rem 0px 0.5rem 2rem;
    margin: 0px;
    color: rgb(139, 149, 158);
    i, .mdi {
      position: absolute;
      left: 0;
      top: 7px;
      color: #FF8309;
    }
  }
}

#testimonials{
  padding: 50px 0;
}



@media (max-width: 800px) {
  body {
    padding: 0;
  }
  #footer {
    .navbar-nav {
      flex-direction: column;
    }
  }
  .min-vh-100 {
    min-height: initial !important;
  }
  .social-links {
    flex-direction: row;
  }
  .col-image{
    order: 1;
  }
  video {
    width: 100% !important;
  }
  .container-section {
    h2 {
      font-size: 1.3rem;
    }
    p {
      font-size: 1rem;
    }
  }
  .list{
    font-size: 1rem;
  }
}

.btn-primary {
  color: #fff;
  background-color: #ff7f00;
  border-color: #ff7f00;
  &:hover {
    color: #fff;
    background-color: #f17800;
    border-color: #f17800;
  }
  &.btn-check:active+.btn-primary, 
  &.btn-check:checked+.btn-primary, 
  &.btn-primary.active, 
  &.btn-primary:active, 
  &.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #ff7f00;
    border-color: #ff7f00;
  }
  .btn-check:active+.btn-primary:focus, 
  .btn-check:checked+.btn-primary:focus, 
  .btn-primary.active:focus, 
  .btn-primary:active:focus, 
  .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }
}